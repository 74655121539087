import { LinearProgress } from '@mui/material'

import useLoadingState from '@/hooks/context/useLoadingState'

const ProgressLoader: React.FC = () => {
  const { progress } = useLoadingState()

  return (
    <LinearProgress
      className='absolute z-[1000] h-0.5 w-full rounded-none bg-surface dark:bg-dark-surface'
      value={progress}
    />
  )
}

export default ProgressLoader
